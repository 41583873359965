import React from 'react'
import createIcon from '../createSvgIcon'

const path = (
    /* eslint-disable max-len */
    <g>
        <path
            fill='#46b549'
            d='M46.604 47.46c-1.75 2.76-4.76 4.28-7.84 4.28-1.67 0-3.36-.45-4.88-1.39a9.124 9.124 0 0 1-4.1-5.73 9.06 9.06 0 0 1 1.18-6.9c2.7-4.27 8.4-5.56 12.71-2.88 2.1 1.3 3.55 3.33 4.1 5.73.55 2.38.13 4.83-1.17 6.89zM17.724 29.5c-1.56 2.46-4.24 3.82-6.98 3.82-1.49 0-3-.4-4.35-1.24a8.073 8.073 0 0 1-3.65-5.11 8.023 8.023 0 0 1 1.05-6.15c2.4-3.8 7.48-4.96 11.33-2.57a8.12 8.12 0 0 1 3.65 5.11c.48 2.12.11 4.3-1.05 6.14zm21.82-27.53c3.86 0 7.01 3.11 7.01 6.94 0 3.84-3.15 6.96-7.01 6.96-3.87 0-7.01-3.12-7.01-6.96 0-3.83 3.14-6.94 7.01-6.94zm11.15 37.93a12.06 12.06 0 0 0-5.42-7.58c-5.32-3.31-12.24-2.11-16.11 2.79l-.28.35-8.6-5.34.2-.41c1.24-2.6 1.35-5.65.3-8.37l-.15-.4 11.7-6.7.27.33a9.001 9.001 0 0 0 6.94 3.26c4.96 0 8.99-4 8.99-8.92 0-4.91-4.03-8.91-8.99-8.91s-8.99 4-8.99 8.91c0 1.22.25 2.4.73 3.52l.18.41-11.59 6.64-.26-.38c-.84-1.2-1.89-2.2-3.14-2.98-5.03-3.13-11.68-1.61-14.83 3.37a10.512 10.512 0 0 0-1.37 8.03c.64 2.79 2.33 5.16 4.77 6.68 4.61 2.87 10.67 1.86 14.09-2.35l.27-.34 8.6 5.35-.2.4c-2.76 5.59-.83 12.29 4.48 15.6 5.72 3.55 13.28 1.83 16.86-3.84 1.72-2.73 2.27-5.96 1.55-9.12z'/>
    </g>

    /* eslint-enable */
);
const Intelligence = createIcon(path, {
    width: 51,
    height: 55,
    viewBox: '0 0 51 55'
})

export default Intelligence
