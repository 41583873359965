import React, { PureComponent } from 'react'

import Footer from '../../components/Footer'

class FooterContainer extends PureComponent {
    static propTypes = {}

    static defaultProps = {}

    render() {

        return(
            <Footer />
        )
    }
}

export default FooterContainer