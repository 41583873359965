const LanguagesData = [
  {
    "englishName": "English",
    "code": "en",
    "nativeName": "US English"
  },
  {
    "englishName": "Slovak",
    "code": "sk",
    "nativeName": "Slovenčina"
  }
]

export default LanguagesData