import React from 'react'
import createIcon from '../createSvgIcon'

const path = (
    /* eslint-disable max-len */
    <g>
        <path
            fill='#46b549'
            d='M20.181 15.325c-1.454 0-2.645-1.289-2.645-2.859s1.166-2.86 2.645-2.86c1.477 0 2.646 1.29 2.646 2.86 0 1.57-1.169 2.86-2.646 2.86zm-9.468 0c-1.453 0-2.646-1.289-2.646-2.859s1.167-2.86 2.646-2.86c1.478 0 2.672 1.29 2.645 2.86 0 1.57-1.167 2.86-2.645 2.86zm16.263-12.62C23.242-.077 19.688 0 19.688 0l-.363.412c4.41 1.34 6.458 3.271 6.458 3.271a21.243 21.243 0 0 0-7.807-2.473 22.053 22.053 0 0 0-5.24.053c-.156 0-.286.024-.44.051-.908.077-3.114.412-5.889 1.623-.959.436-1.53.746-1.53.746S7.03 1.648 11.698.308L11.44 0S7.886-.077 4.15 2.705c0 0-3.734 6.722-3.734 15.014 0 0 2.177 3.736 7.91 3.916 0 0 .961-1.159 1.739-2.138-3.294-.979-4.539-3.039-4.539-3.039s.259.18.725.437c.027.026.052.053.105.077.078.051.154.078.232.13.649.36 1.298.644 1.894.876 1.064.411 2.335.824 3.814 1.107 1.945.36 4.227.49 6.717.025a17.174 17.174 0 0 0 3.763-1.108 14.886 14.886 0 0 0 2.982-1.519s-1.298 2.113-4.695 3.065c.778.98 1.713 2.087 1.713 2.087 5.73-.18 7.936-3.916 7.936-3.916 0-8.292-3.736-15.014-3.736-15.014z'/>
    </g>

    /* eslint-enable */
);
const Discord = createIcon(path, {
    width: 31,
    height: 22,
    viewBox: '0 0 31 22'
})

export default Discord
