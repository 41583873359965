import React from 'react'
import createIcon from '../createSvgIcon'

const path = (
    /* eslint-disable max-len */
    <g>
        <path
            fill='#46b549'
            d='M33.44 13.98c-5.37-5.36-14.1-5.37-19.47 0-2.59 2.6-4.02 6.05-4.02 9.73 0 3.68 1.43 7.14 4.02 9.73 2.6 2.6 6.06 4.03 9.74 4.03 3.68 0 7.13-1.43 9.73-4.03 5.36-5.36 5.36-14.09 0-19.46zm2.24 21.71c-6.61 6.61-17.36 6.62-23.97.02-6.61-6.62-6.61-17.38 0-23.99A16.94 16.94 0 0 1 23.7 6.76c4.34 0 8.68 1.66 11.99 4.97 6.61 6.6 6.6 17.35-.01 23.96zM47.4.53v7.51a.514.514 0 0 1-.52.52h-.68c-.27 0-.53-.26-.53-.52V2.97l-5.55 5.55c-.11.11-.24.17-.39.17a.575.575 0 0 1-.36-.14l-.48-.49a.546.546 0 0 1-.01-.76l5.59-5.58-5.11.01c-.18 0-.3-.1-.36-.16-.1-.1-.15-.23-.15-.36V.53a.514.514 0 0 1 .52-.52h7.51c.13 0 .25.04.34.13l.03.03c.1.1.15.22.15.36zM8.04 45.69c.16-.02.3.1.36.16.1.1.15.23.15.36v.68a.514.514 0 0 1-.51.52H.52a.49.49 0 0 1-.36-.15l-.03-.03a.468.468 0 0 1-.13-.34v-7.52a.514.514 0 0 1 .52-.51h.68c.27 0 .53.26.53.52v5.07l5.55-5.55c.21-.22.55-.23.75-.03l.48.49c.1.1.15.23.16.38 0 .15-.06.28-.15.38L2.93 45.7l5.11-.01zm39.21-6.68c.1.1.15.23.15.37v7.51a.5.5 0 0 1-.16.37c-.1.1-.22.15-.36.15h-7.52a.514.514 0 0 1-.51-.52v-.68c0-.27.26-.53.52-.53h5.07l-5.55-5.55a.538.538 0 0 1-.17-.39c0-.14.05-.26.14-.36l.49-.48c.09-.1.23-.16.38-.16s.28.06.38.15l5.58 5.59-.01-5.11c0-.18.1-.3.16-.36.1-.1.23-.16.36-.16h.68c.14 0 .27.06.37.16zM8.51 7.29c.11.11.17.25.17.4a.5.5 0 0 1-.14.35l-.49.48c-.09.1-.24.16-.38.16-.15 0-.28-.06-.38-.15L1.71 2.94l.01 5.11c0 .18-.1.3-.16.36-.1.1-.23.15-.36.15H.52A.514.514 0 0 1 0 8.04V.53C0 .39.05.27.15.17L.17.14C.27.05.39.01.52.01h7.51c.16-.04.27.05.37.15.1.1.15.23.15.37v.68c0 .27-.26.53-.52.53H2.96l5.55 5.55z'/>
    </g>

    /* eslint-enable */
);
const Decentralization = createIcon(path, {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48'
})

export default Decentralization
