import React from 'react'
import createIcon from '../createSvgIcon'

const path = (
    /* eslint-disable max-len */
    <g>
        <path fill='#46b549'
              d='M33 55.19c-4.9 0-8.89-3.96-8.89-8.83 0-4.86 3.99-8.81 8.89-8.81s8.89 3.95 8.89 8.81c0 4.87-3.99 8.83-8.89 8.83zm0-20.46c-6.46 0-11.72 5.22-11.72 11.63C21.28 52.78 26.54 58 33 58c6.46 0 11.72-5.22 11.72-11.64 0-6.41-5.26-11.63-11.72-11.63z'/>

        <path fill='#46b549'
              d='M63.87 31.91H52.35V18.59h11.52zm-36.63-16.5V2.09h11.52v13.32zM13.65 32.26H2.13V18.94h11.52zM51.73 16.5c-.86 0-1.51.64-1.51 1.49v6.8H33.77V17.5h5.62c.86 0 1.5-.64 1.5-1.48V1.48c0-.84-.64-1.48-1.5-1.48H26.62c-.86 0-1.51.64-1.51 1.48v14.54c0 .84.65 1.48 1.51 1.48h5.54v7.29H15.78v-6.45c0-.85-.64-1.49-1.5-1.49H1.51c-.86 0-1.51.64-1.51 1.49v14.54c0 .85.63 1.47 1.51 1.47h12.77c.87 0 1.5-.62 1.5-1.47v-6.53h16.38v4.01h1.61v-4.01h16.45v6.18c0 .85.64 1.47 1.51 1.47H64.5c.87 0 1.5-.62 1.5-1.47V17.99c0-.85-.64-1.49-1.5-1.49z'/>
    </g>

    /* eslint-enable */
);
const Persistence = createIcon(path, {
    width: 66,
    height: 58,
    viewBox: '0 0 66 58'
})

export default Persistence
