import React from 'react'
import createIcon from '../createSvgIcon'

const path = (
    /* eslint-disable max-len */
    <g>
        <path
            fill='#46b549'
            d='M29.732 14.756C29.732 6.607 23.102 0 14.923 0 6.744 0 .114 6.607.114 14.756c0 6.468 4.177 11.964 9.99 13.956.355-.255.763-.569.763-1.098 0-.812-.018-2.899-.018-2.899s-.643.11-1.56.11c-2.312 0-3.043-1.463-3.39-2.28-.453-1.066-1.04-1.567-1.664-1.967-.383-.246-.471-.535-.028-.618 2.046-.382 2.569 2.3 3.936 2.727.975.305 2.229.173 2.853-.227.082-.819.678-1.527 1.174-1.9-3.476-.33-5.536-1.527-6.605-3.45l-.114-.215-.269-.61-.079-.22c-.335-.976-.483-2.093-.483-3.342 0-2.217.697-3.053 1.633-4.235-.716-2.527.257-4.254.257-4.254s1.504-.308 4.348 1.71c1.541-.656 5.65-.709 7.595-.146 1.193-.782 3.376-1.89 4.256-1.58.239.38.753 1.49.312 3.925.299.535 1.853 1.673 1.859 4.89-.025 1.187-.15 2.19-.381 3.037l-.128.433s-.074.204-.154.4l-.094.215c-1.031 2.244-3.146 3.081-6.563 3.43 1.107.691 1.425 1.557 1.425 3.903 0 2.345-.031 2.66-.024 3.2.006.476.389.82.733 1.078 5.838-1.978 10.038-7.487 10.038-13.973z'/>
    </g>

    /* eslint-enable */
);
const Github = createIcon(path, {
    width: 30,
    height: 29,
    viewBox: '0 0 30 29'
})

export default Github
